import { createStore } from 'vuex'

export default createStore({
  state: {
    screenWidth: document.documentElement.clientWidth, //屏幕宽度
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
