<template>
  <router-view />
</template>
<script>
export default {
  data () {
    return {}
  },
  mounted () {
    let _this = this;
    window.onresize = function () {
      _this.$store.state.screenWidth =
        document.documentElement.clientWidth; //窗口宽度
    };
  },
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>
